/*******************************************************/

/* Add/remove custom styles to this theme file */

/*******************************************************/

@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css');

/***** your custom styles ****/
.feature-blocks {
	img {
		max-width: 400px;
		width:100%;
		@include media(sm) {
			margin-bottom: 2em;
		}
	}
}
#hero {
	h3.subtitle {
		font-weight: 300;
	}
}
.background-float {
  // background-image: url("/assets/images/header-recs.png");
  background-size: 700px;
  background-position: right top;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  @media screen and (max-width: 992px){
    opacity: 0.4;
  }
}
.section-header{
  position: relative;
  z-index: 10;
}
/***** theme variables below this line ****/
section.hero{
  padding-top: 180px !important;
	&.shopstatus{
		img.img-fluid {
			margin-top: -150px;
			@include media(sm) {
		    margin-top: 0px;
		  }
		}
	}
}
html {
	text-rendering: optimizelegibility;
	position: relative;
	min-height: 100%;
}

html,body {
	width: 100%;
	margin: 0px;
  padding: 0px;
}

body {
	-webkit-overflow-scrolling: touch;
}

body, p {
  //letter-spacing:.025em;
  font-weight: 400;
  text-rendering: optimizelegibility;
	font-size: 16px;
  line-height: 1.6;
}

a {
  font-weight: $link-weight !important;
  color: $link-color;
}

a:hover, a:focus {
  font-weight: $link-hover-weight !important;
  color: $link-hover-color !important;
}

h1,h2,h3,h4,h5 {
  font-weight: $heading-font-weight;
  font-family: $heading-font, "Helvetica", sans-serif !important;
  color: $heading-font-color;
  text-rendering: optimizelegibility;
}
h1, .h1 {
	font-size: 3em !important;
}

h2, .h2 {
  font-weight: $heading-font-weight;
  font-family: $heading-font, "Helvetica", sans-serif !important;
  color: $heading-font-color;
  text-rendering: optimizelegibility;
	// text-transform: uppercase;
	font-size: 2em !important;
}

.h3, h3 {
	font-size: 1.25em;
	font-weight: 600;
	font-style: bold;
	line-height: 1.4;
}

h3, h4 {
  align-content: center;
  text-transform: none;
  font-family: $subheading-font;
}

body, p {
font-family: $primary-font, "Helvetica", sans-serif !important;
font-weight: $primary-font-weight !important;
color: $primary-font-color;
}
#why {
  background-image: $gradient-bg;
  padding: 17px 17px !important;
  .container.box{
    max-width: 100% !important;
    width: 100% !important;
  }
}
.img-responsive{
  max-width: 100%;
}
section .container.box {
	background: white;
	width: 94%;
	max-width: 900px;
	padding: 30px;
	.icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
	ul {
		list-style: none;
		li {
			margin: .5em 0 1.25em;
			font-size: 1.25em;
		}
		li::before {
	    font-family: "Font Awesome 5 Free"; font-weight: 600; content: "\f00c";
			margin-right: 30px;
	  }
	}

}
/// new section overrides

section {
  background-color: transparent;
  padding: $section-padding;
  position:relative;
  body, p, li, ul, h1, h2, h3, h4, h5 { color:$section-font-color !important;}
  h1, h2 { color:$section-heading-font-color !important;}
  h3, h4, h5, h6 { color:$section-subheading-font-color !important;}
  @include media(sm) {
    padding: $section-padding-mobile !important;
  }
}
section.alt, div.alt {
  background-color: $section-alt-bg;
  body, p, li, ul, h1, h2, h3, h4, h5, strong { color:$section-alt-font-color !important;}
  h1, h2 { color:$section-alt-heading-font-color !important;}
  h3, h4, h5, h6 { color:$section-alt-subheading-font-color !important;}
  @include media(sm) {
    padding: $section-padding-mobile !important;
  }
}

section.dark, div.dark {
  background-color: $section-dark-bg;
  body, p, li, ul, h1, h2, h3, h4 { color:$section-dark-font-color !important;}
  h1, h2 { color:$section-dark-heading-font-color !important;}
  h3, h4, h5, h6 { color:$section-dark-subheading-font-color !important;}
  @include media(sm) {
    padding: $section-padding-mobile !important;
  }
}

section.dark.gradient {
  background-color: $section-dark-bg;
  background-image: $section-dark-bg-grad;
  body, p, li, ul, h1, h2, h3, h4 { color:$section-dark-font-color !important;}
  h1, h2 { color:$section-dark-heading-font-color !important;}
  h3, h4, h5, h6 { color:$section-dark-subheading-font-color !important;}
  @include media(sm) {
    padding: $section-padding-mobile;
  }
}

section.bgclear {
  background-color: transparent !important;
  body, p, li, ul, h1, h2, h3, h4, h5 { color:$section-bgclear-font-color !important;}
  h1, h2 { color:$section-bgclear-heading-font-color !important;}
  h3, h4, h5, h6 { color:$section-bgclear-subheading-font-color !important;}
  @include media(sm) {
    padding: $section-padding-mobile;
  }
}
section.grey{
  background-color: #efefef;
  padding: $section-padding;
  body, p, li, ul, h1, h2, h3, h4, h5 { color:$section-font-color !important;}
  h1, h2 { color:$section-heading-font-color !important;}
  h3, h4, h5, h6 { color:$section-subheading-font-color !important;}
  @include media(sm) {
    padding: $section-padding-mobile;
  }
}
.bgclear {background-color: transparent !important;}


#footer {
  background-color:$footer;
  padding: 15px 0 0;
  color: $footer-font-color;
  border-top: $footer-border !important;
  margin-top:-1px;
	min-height: 160px;
	align-items: center;
	justify-content: center;
  display: flex;
  font-size: 12px;
  a, a:visited, a:hover, a:active {
    color:$footer-link-color !important;
    &:hover{
      text-decoration: none !important;
    }
    }
  a:hover, a:active {
    color:$footer-link-hover-color !important;
    }
  i {
    color:$footer-icon-color;}
    .subfooter {
      text-align: center;
			min-height: 60px;
      display: flex;
			flex-direction: column;
      align-items: center;
			justify-content: center;
      background-color: $subfooter-bg;
      color:$subfooter-font-color !important;
      padding: 0 3em;
			p, span {color:$subfooter-font-color !important;}
      a, a:visited, a:hover, a:active {
        color:$subfooter-link-color !important;
        }
      }
      .upperfooter{
        padding: 0 3em 35px;
      }
      div.row{
        .text-left{
          @include media(sm){
            text-align: center !important;
          }
      }
    }
}
.footer-nav-list{
  list-style: none;
  text-align: left;
  font-weight: lighter;
  margin-left:0;
  align-items: flex-start;
  @include media(sm){
    align-items: center;
  }
  a {
    font-weight: 200 !important;
    line-height: 1.5;
  }
}
#cookie-notice {
	padding: 10px;
	display: none;
	position: fixed;
	z-index: 999;
	bottom: 0;
	width: 100%;
	background: #222;
	p {
		text-align: left !important;
		color: #fff;
		margin-bottom: 0;
	}
	a {
		&:hover {
			color: #aaa !important;
		}
	}
}
#notice-container {
	width: 75%;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}
.navbar-toggler {
  color: white;
}
#nav.navbar {
  height: 106px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(280deg, #232323, #303030 52%, #4f4f4f);
  padding: .5rem 3em;
	@media (max-width: 500px) {padding: .5rem 1em;}
	.navbar-brand {
		img {
			max-width: 220px;
			@media (max-width: 500px) {max-width: 140px;}
		}
	}
}

.nav > li > a, .nav > li, .navbar-toggle.btn-transparent, .nav > .page-link {
  color: white;
	border: none;
  background-color: transparent;
}

.nav > li > a:hover, .nav > li > a:focus, .nav > li > a:visited, .navbar-toggle.btn-transparent:hover, .navbar-toggle.btn-transparent:focus {
	//background-color:$nav-hover;
  background-color: transparent;
  // color:$nav-hover-font-color !important;
	text-decoration: underline;
}

.page-link {
	padding: .5rem 1.5rem;
	&:focus, &:hover, &:visited {
		outline: none;
    box-shadow: none;
    color: white !important;
	}
}

.btn, .btn-sm, .btn-lg, .btn-xs {
  border-radius:$radius !important;
}

.btn, a .btn, .btn:hover, .btn:active, .btn:focus, .btn:visited {
  color: $btn-font-color;
}

.btn-default, a .btn-default {
  color:$default-font-color !important;
  background-color:$default;
  border-color:$default;
}

.btn-default:hover,.btn-default:active,.btn-default:focus {
  background-color: $default-hover;
  border-color: $default-hover;
}

.btn-info, a .btn-info {
  color:$info-font-color !important;
  background-color: $info;
  border-color: $info;
}

.btn-info:hover,.btn-info:active,.btn-info:focus  {
  background-color:$info-hover !important;
  border-color:$info-hover !important;
}

.btn-warning, a .btn-warning {
  color:$warning-font-color !important;
  background-color:$warning;
  border: 2px solid $warning-hover;
}

.btn-warning:hover,.btn-warning:active,.btn-warning:focus  {
  color:$warning-font-color !important;
  background-color:$warning-hover;
  border-color:$warning-hover;
}

.btn-success, a .btn-success {
  color:$success-font-color !important;
  background-color:$success;
  border-color:$success;
}

.btn-success:hover,.btn-success:active,.btn-success:focus,.btn-success:visited  {
  background-color:$success-hover;
  border-color:$success-hover;
}


#content{
  #hero {
  .action.form {
    h2 {
      color: $form-headline-color !important;
      }
    .email-form {
      background: $form-background-color !important;
      border: $form-border;
      box-shadow: $form-box-shadow  !important;
      }
    }
  }
}

.media-wrap {
	width: 100%;
	z-index: -100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	overflow: hidden;
	video, .media {
		z-index: -99;
		vertical-align: baseline;
		object-fit: contain;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
	}
	video {
		height:100%;
	}
	.media-tint, .video-placeholder {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -98;
		background: rgba(0,0,0,.25);
	}
	.media.bgoverlayLight:after {
			content: '';
			position: absolute;
			width: inherit;
			height: inherit;
			top: 0;
			left: 0;
			bottom: 0;
      right: 0;
			height:100%;
			background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.9));
		}
		.media.bgoverlayDark:after {
			content: '';
			position: absolute;
			width: inherit;
			height: inherit;
			top: 0;
			left: 0;
			bottom: 0;
      right: 0;
			height:100%;
			background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9));
		}
}

@media (min-aspect-ratio: 16/9) {
	.media-wrap video .media {
		height: 300%;
		top: -100%;
	}
}

@media (max-aspect-ratio: 16/9) {
	.media-wrap video {
		width: 300%;
		left: -100%;
	}
}

@media (max-width: 767px) {
	.media-wrap.video {
		background: inherit;
		background-size: cover;
	}
	.media-wrap video {
		display: none;
	}
	.element.image {
		img {
			max-width:65% !important;
		}
	}
}

.media-wrap {
  .media-tint, .video-placeholder {
    background: $mediatint-color;
    }
  .media.bgoverlayLight:after, .slider-bgimg.bgoverlayLight:after {
      content: '';
      position: absolute;
      width: inherit;
      height: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $bgoverlayLight;
    }
    .media.bgoverlayDark:after, .slider-bgimg.bgoverlayDark:after {
      content: '';
      position: absolute;
      width: inherit;
      height: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $bgoverlayDark;
    }
  }

.subheader{
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.4px;
}
#features {
	.container {
    //max-width: 100%;
  }
	h2.title, h3.subtitle {
		text-align: center;
	}
	h3.title {
		margin-bottom: 0;
		font-size: 1.5em;
	}
	p{
		margin: 1em 0 2em;
	}
}
.features-row {
  margin: 6em auto;
  .feature-block-image{
    margin-top: -3em;
    .d-flex{
      @include gradient-light;
      padding: 3em .5em;
      div{
        margin: .5em;
      }
    }
  }
  .feature-block-copy{
    background-color: $section-bg;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 3em 6em;
    margin: 50px 0 0 -4em;
    h2 {
      // text-transform: uppercase;
      font-weight: 600;
      font-size: 1.5em;
      margin-bottom: 10px;
    }
    @include box-shadow;
    box-shadow: 7px 7px 21px 0px #c9c9c9;
  }
  @include media(sm){
    margin:0 0 30px;
    .feature-block-image {
      margin: 0;
      padding: 0;
    }
    .feature-block-copy{
      margin: 0;
      padding: 3em 3em;
    }
  }
}
.small-hide{
  @media screen and (max-width: 992px){
    display: none !important;
  }
}
@media screen and (max-width: 992px){
  .navbar-collapse{
    background-color: rgba(0,0,0, 0.75);
    position: absolute;
    top: 106px;
    right: 0px;
    width: 360px;
    ul{
      text-align: right;
      li > a{
        color: white;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
.featured-image-box{
  padding: 1em;
  background-color: #fff;
  p{
    padding: 1em 2em;
  }
}
.text-pink{
  color: $pink;
}
.text-green{
  color: $green;
}
.text-purple{
  color: $default;
}
header {
  position: absolute;
  z-index: 110;
  width: 100%;
  background: transparent;
}
.docs-image{
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 100%;
  &.left{
    margin-right: 2em;
    float: left;
    max-width: 500px;
    width: 40%;
  }
  &.right{
    float: right;
    max-width: 500px;
    width: 40%;
    margin-left: 2em;
  }
}
#client-id-field{
  width: 60%;
  max-width: 350px;
  padding: .25em;
}
.gradient-bars-wrap{
  .gradient-bars-lower{
    position: relative;
    width: 100%;
    height: 17px;
    background-color: #cfe8c9;
  }
}
#image-content{
  border-top: 14px solid #cfe8c9;
}
.footer-logo{
  margin: 0 .3em;
  height: 20px;
}
.post-content{
  max-width: 1060px;
  margin: 0 auto;
  img{
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 1em auto;
		border: 1px solid #efefef;
		box-shadow: 1px 1px 3px rgba(0,0,0,.15);
  }
}
.post-title{
  max-width: 380px;
}
.header-wrapper {
  .title{
    line-height: 1.3;
  }
  h3.subtitle {
    font-weight: 100;
    font-family: $primary-font, "Helvetica", sans-serif !important;
    margin-top: 1em;
    font-size: 22px;
	}
}
.powered-by-sumo, .sumo-logo {
  display: none !important;
  opacity: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
}

#expertise{
  h3 {
    text-transform: uppercase;
  }
  .logos{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
    a{
      text-decoration: none;
      outline: none;
      }
    img {
      max-width:140px;
			width: auto;
			box-shadow: none;
			border: none;
      //max-height:50px;
    }
  }
	@media (max-width: 1024px) {
		.logos{
			display: block;
			flex-direction: unset;
			justify-content: unset;
			align-items: unset;
			a{
	      padding: 10px 15px;
				vertical-align: middle;
	    }
		}
	}
}
.image-row{
  margin-bottom: 4em;
}
// Pricing *************************
.plan-image{
  text-align: center;
  padding: 1.5em;
  h1{
    text-align: center !important;
  }
  h3{
    text-align: center !important;
  }
  &.yellow{
    background-color: #ba7e00;
  }
  &.green{
    background-color: #3db639;
  }
  &.dark-green{
    background-color: #2c9032;
  }
}
.card-header {
	&.dark-green{
    background-color: #2c9032;
		p, a, h5  {
			color: #fff !important;
			&:hover {
				text-decoration: none;
			}
		}
		h5 {
			font-family: 'Lato' !important;
		}
		i {
			margin-left: 10px;
		}
  }
}
.team-member-card{
  margin:auto;
  max-width: 400px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
}
.plan-copy{
  padding: 1em 2em 2em;
  border: solid 1px #979797;
  border-top: none;
  .features{
    padding: 1em;
  }
  .features:not(:first-child){
    border-top: 1px solid lightgrey;
  }
}
.plan-tagline{
  color: #212b36;
  margin-top: 5px;
}
.border-top {
	border-top: 14px solid #cfe8c9 !important;
}
.post-head-wrap {
	border-bottom: 14px solid #cfe8c9 !important;
	min-height: 300px;
	background-image: linear-gradient(229deg, #ffffff, #f4f4f4);
}
