//--------------------------
// MIXINS
//--------------------------

@mixin button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border: $border;
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
}

@mixin body-font {
    font-family: "OpenSans", Arial, sans-serif;
}

@mixin fancy-heading-font {
    font-family: "SignPainter-HouseScript", "Times New Roman", Times, serif;
}

@mixin heading-font {
    font-family: "OpenSans", Arial, sans-serif;
}

@mixin truncate-single-line{
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin truncate{
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}


@mixin bggradient {
  background: #003b49;
  background-image: url("../images/bg-pattern.png"), linear-gradient(88deg, #003b49, #0099bf); /* W3C */
  background-repeat: repeat-y;
}

// slanted edges mixin
@mixin angle($pseudo, $flip: false, $angle: 2.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: 1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: inherit;
      content: '';
      display: block;
      height: 90%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}


@mixin skew-top {
  @include angle(before);
}

@mixin skew-bottom {
  @include angle(after);
}

@mixin skew-both {
  @include angle(both);
}

@mixin skew-top-reverse {
  @include angle(before, true);
}

@mixin skew-bottom-reverse {
  @include angle(after, true);
}

@mixin skew-both-reverse {
  @include angle(both, true);
}

@mixin skew-bottom-small {
  @include angle(after);
  height: 90%;
}

@mixin box-shadow {
  -webkit-box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.24);
  -moz-box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.24);
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.24);
}
@mixin gradient-blue{
  background: rgba(16,63,74,1);
  background: -moz-linear-gradient(-45deg, rgba(16,63,74,1) 0%, rgba(11,198,219,1) 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(16,63,74,1)), color-stop(100%, rgba(11,198,219,1)));
  background: -webkit-linear-gradient(-45deg, rgba(16,63,74,1) 0%, rgba(11,198,219,1) 100%);
  background: -o-linear-gradient(-45deg, rgba(16,63,74,1) 0%, rgba(11,198,219,1) 100%);
  background: -ms-linear-gradient(-45deg, rgba(16,63,74,1) 0%, rgba(11,198,219,1) 100%);
  background: linear-gradient(135deg, rgba(16,63,74,1) 0%, rgba(11,198,219,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#103f4a', endColorstr='#0bc6db', GradientType=1 );
}
@mixin gradient-lightblue{
  background: rgba(4,165,211,1);
  background: -moz-linear-gradient(top, rgba(4,165,211,1) 0%, rgba(0,60,71,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(4,165,211,1)), color-stop(100%, rgba(0,60,71,1)));
  background: -webkit-linear-gradient(top, rgba(4,165,211,1) 0%, rgba(0,60,71,1) 100%);
  background: -o-linear-gradient(top, rgba(4,165,211,1) 0%, rgba(0,60,71,1) 100%);
  background: -ms-linear-gradient(top, rgba(4,165,211,1) 0%, rgba(0,60,71,1) 100%);
  background: linear-gradient(to bottom, rgba(4,165,211,1) 0%, rgba(0,60,71,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#04a5d3', endColorstr='#003c47', GradientType=0 );
}
@mixin gradient-light {
  background: #f4f4f4;
  background: linear-gradient(229deg, #ffffff, #f4f4f4);
  border: 1px solid #efefef;
}
