// this file should be kept very lightwieght and include styles for above the fold content.


@media(max-width:768px){.clearfix-xs:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
    }
}
.full-width{
  padding: 0 !important;
  .container{
    padding:0 !important;
    margin: 0 !important;
    max-width: 100vw !important;
    // max-height: 800px;
  }
}
.carousel-wrap{
  position: relative;
  background-color: rgba(#007795,1);
}
.welcome-panel, .welcome-panel-mobile {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  min-width: 420px;
  @include media(sm){
    position: relative;
  }
  @media screen and (max-width: 725px){
  }
  .welcome-message{
    padding: 1em;
    height: 100%;
    background-color: rgba(#007795, 0.85);
    margin-left: 0;
    .welcome-inner{
      max-width: 340px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 2em auto;
      @include media(sm){
        max-width: 600px;
        margin: 1em auto 1em;
      }
      p{
        color: #eee !important;
        margin-bottom: 1em;
        font-size: .9em;
      }
      h4 {
        margin-top: 10px;
        font-weight: 600;
        @include media(sm){
          margin: 0 auto .5em;
        }
      }
      a{
        @include media(sm){
          margin: 0 auto;
          }
        }
    }
  }
  @include media(md) {
    min-width: 340px;
  }
  @include media(sm) {
    width: 100%;
  }
}
.btn-green{
  width: auto;
  height: 38px;
  background-color: #b8e986;
  color: #fff;
  border: none;
}
.carousel-wrap-nav {
  position: absolute;
  z-index: 4;
  margin: -40px auto 0;
  width: 80%;
  left: 10%;
  height: 80px;
  min-width: 480px;
  background-color: $section-dark-bg;
  padding: 8px 0 14px;
  h4 {
    font-size: .8rem;
    font-weight: 500;
  }
  @include media(sm){
    left: 0;
    min-width: unset !important;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 !important;
    justify-content: center;
    margin: -20px auto 0;
    .text-center.slick-slide {
      height: unset !important;
      min-height: unset !important;
      display: block !important;
    }
  }
}
.slick-initialized .slick-slide {
  @include media(sm){
    height: unset;
    // min-height: 600px;
    // display: flex !important;
    // align-items: flex-end;
    }
  }
.slider-nav {
  .slick-track{
    transform: unset !important;
  }
  h4{
    margin: 10px 0;
    font-size: .9rem;
    color: #fff;
    padding: 10px;
    padding-bottom: 0;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: none;
    }
    &.slick-current{
      color: #b8e986;
      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        padding-top: 5px;
        width: 80px;
        border-bottom: 2px solid #b8e986;
      }
    }
  }
  @include media(sm){
    .slick-list.draggable {
      padding: 0 !important;
      width: 100%;
    }
    .slick-track{
      width:100vw !important;
      opacity: 1;
      display: flex;
      align-items: flex-start;
      padding: 0 !important;
      justify-content: space-between;
      width: 100%;
    }
    h4 {
      font-size: .75rem;
      width: 100%;
    }
  }
}
.slick-dots{
  position: absolute;
  z-index: 3;
  bottom: 2em;
}
.request-button{
  padding: .4em 2.5em;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  background-color: white;
  border: 2px solid $section-dark-bg;
  border-radius: 6px;
  color: $section-dark-bg;
  &:hover{
    background-color: #fafafa;
    cursor: pointer;
  }
  &.light{
    border: 2px solid $section-alt-bg;
    color: $section-alt-bg;
    padding: .75em 1.5em;
  }
}
.breadcrumb{
  background-color: transparent !important;
  position: absolute;
  bottom: 2px;
  left: 6px;
  li:after{
    margin: 0 6px;
    content: ' >';
  }
  li:last-child:after{
    content: '';
  }
}
// slider

.carousel-wrap, .slider-wrap{
  max-height: 600px;
  overflow: hidden;
  @include media(sm){
    max-height: unset;
  }
  @media screen and (max-width: 630px ){
    //max-height: 270px;
  }
  @media screen and (max-width: 516px ){
    //max-height: 250px;
  }
  @media screen and (max-width: 460px ){
    //max-height: 210px;
  }
  @media screen and (max-width: 410px ){
    //max-height: 170px;
  }
}
