//-------------
// HELPER CLASSES
//-------------

form {
  label {
    display: block;
  }

  input[type="radio"] {
    display: none;
  }

  .form-check {
    padding-left: 0;
  }
}

#root{
  height: 100%;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.center {
  align-items: center;
  justify-content: center;
}

.side-column-right{
  border-left: 2px lightgrey solid;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-space-around {
  justify-content: space-around;
}
.w90 {width: 90%;}
.w80 {width: 80%;}
.w70 {width: 70%;}
.w60 {width: 60%;}
.w50 {width: 50%;}
.w40 {width: 40%;}
.w30 {width: 30%;}
.w20 {width: 20%;}
.w10 {width: 10%;}
.w90, .w80, .w70, .w60, .w50, .w40, .w30, .w20, .w10 {
  @include media(sm) {
    width: 100%;
  }
}

// slanted edge helper classes
.skew-bottom {
  @include skew-bottom;
}

.skew-bottom-reverse {
  @include skew-bottom-reverse;
}

.skew-top {
  @include skew-top;
}

.skew-top-reverse {
  @include skew-top-reverse;
}

.skew-both {
  @include skew-both;
}

.skew-both-reverse {
  @include skew-both-reverse;
}

section.cover {
	background:no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

section.tile {
	background-size: inherit !important;
	background-repeat:repeat !important;
}

.parallax {
	background-attachment:fixed !important;
}

.fullscreen {
	height:100%;
	height:100vh;
	padding:15% 10% !important;
}

.row-90, .row-80, .row-70, .row-60, .row-50, .row-40, .row-30, .row-20 {
  margin: auto !important;
}
.row-90 {
	width: 90%;
}
.row-80 {
	width: 80%;
}
.row-70 {
	width: 70%;
}
.row-60 {
	width: 60%;
}
.row-50 {
	width: 50%;
}
.row-40 {
	width: 40%;
}
.row-30 {
	width: 30%;
}
.row-20 {
	width: 20%;
}


// helper classes for media


img.quarter{
	width:25%;
}
img.third{
	width:33%;
}
img.half{
	width:50%;
}
img.threequarter{
	width:75%;
}
.left-buffer {
	margin-left:40px !important;
}
.right-buffer {
	margin-right:40px !important;
}

section.no-padding{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding: 0 !important;
  @include media(md){
    padding-bottom: 2em !important;
  }
}